import * as React from "react"
import Layout from "../components/layout"
import Blog1 from "../components/Blogs/Blog1"

const blog1 = () => {
  return (
    <Layout>
      <Blog1 />
    </Layout>
  )
}
export default blog1
